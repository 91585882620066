export function FormList(props){

    const {code, forms, backToSearch, selectCallBack} = props;

    const toDateString = (secs) => {
        var t = new Date(Date.UTC(1970, 0, 1)); // Epoch
        t.setUTCSeconds(secs);
        return ("0" + t.getDate()).slice(-2) + "/" + ("0"+(t.getMonth()+1)).slice(-2) + "/" +t.getFullYear();
    }

    return <>
    <div><button type="submit" className="btn btn-primary" onClick={backToSearch}>Retourner à la liste des codes</button></div>

    <label>Tests passés avec le code {code.code}</label>
    <table className="table">
        <thead>
            <tr>
                <th scope="col">Date</th>
                <th scope="col">Genre</th>
                <th scope="col">Role</th>
                <th scope="col">Profession</th>
            </tr>
        </thead>
        <tbody>
            {forms.map((form) => {
                return <tr key={form.id} style={{cursor: "pointer"}} onClick={()=>selectCallBack(form)}>
                    <td>{toDateString(form.created._seconds)}</td>
                    <td>{form.cat ? form.cat.gender : ""}</td>
                    <td>{form.cat ? form.cat.role : ""}</td>
                    <td>{form.cat ? form.cat.prof : ""}</td>
                </tr>;
            })}
        </tbody>
    </table>
    </>
}