import { useEffect } from "react";
import { useState } from "react";


export function UsersList(props) {

    const originalUser = props.user;
    const createdUser = props.createdUser;
    const [users, setUsers] = useState([]);

    useEffect(() => {
        originalUser.getIdToken(true)
            .then((token) => {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': ' application/json',
                        'Authorization': token
                    }
                };
                return fetch(process.env.REACT_APP_BACKEND_URL + '/api/admin/users/list', requestOptions);
            })
            .then((res) => res.json())
            .then((data) => {
                setUsers(data.items);
            });
    }, [originalUser, createdUser]);

    return (
        <div>
            <table className="table">
                <thead><tr>
                    <th scope="col">Nom</th>
                    <th scope="col">Email</th>
                    <th scope="col">Groupe(s)</th>
                    <th scope="col">Administrateur</th>
                </tr></thead>
                <tbody>
                    {users.map((user => {
                        return <tr key={user.uid}>
                            <td>{user.displayName}</td>
                            <td>{user.email}</td>
                            <td>{user?.customClaims?.groups ? user.customClaims.groups.join(", "):  ""}</td>
                            <td>{user?.customClaims?.admin ? "oui":  ""}</td>
                        </tr>
                    }))}
                </tbody>
            </table>
        </div>
    );
}