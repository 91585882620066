import { useState } from 'react';
import PdfItem from './PdfItem';

export function IndividualDownload(props) {
    const { title, file, icon, suggestedDownloadName, showPdf, rotate} = props;
    const [modalVisible, setModalVisible] = useState(false);

    const downloadFile = () => {
        fetch(file)
            .then(response => response.blob())
            .then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = suggestedDownloadName;
                alink.click();
            });
    };

    return (
        <li className="list-group-item">
            <div className="row">
                <div className="col">
                    <img src={icon} alt="Icone" className="svg-icon mr-3" />
                    {title}
                </div>
                <div className="col">
                    {showPdf &&
                        <button type="button" className="btn btn-light mx-3" onClick={() => setModalVisible(true)} disabled={modalVisible}>Lire en ligne</button>
                    }
                    <button type="button" className="btn btn-light mx-3" onClick={downloadFile}>Télécharger</button>
                </div>

                {showPdf &&
                    <PdfItem
                        visible={modalVisible}
                        setVisible={setModalVisible}
                        file={file}
                        scale={1.0} 
                        rotate={rotate}/>
                }
            </div>
        </li>
    );
}
