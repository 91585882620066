import { signInWithEmailAndPassword } from "firebase/auth";
import { Formik, Field, Form } from 'formik';
import { useState } from "react";
import { auth } from "../../firebase-config";

export function Login(props) {

    const [errorMessage, setErrorMessage] = useState(null);

    return (
        <div>
            <legend>Accéder à votre Espace Soignant</legend>
            <div className="row justify-content-center">
                <div className="col-xs-12">
                    <Formik
                        initialValues={{
                            email: '',
                            password: ''
                        }}
                        onSubmit={async (values) => {
                            setErrorMessage(null);
                            try {
                                await signInWithEmailAndPassword(
                                    auth,
                                    values.email,
                                    values.password
                                );
                            } catch (error) {
                                if (error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found'){
                                    setErrorMessage("La combinaison email/mot de passe n'est pas valide.");
                                }else{
                                    setErrorMessage("Erreur de connexion : "+error.message);
                                }
                            }
                        }}
                    >
                        <Form className="border border-primary rounded p-3">

                            {errorMessage && <div className="alert alert-danger" role="alert">
                                {errorMessage}
                            </div>
                            }

                            <div className="form-group row">
                                <label className="col-3 col-form-label" htmlFor="email">Email</label>
                                <div className="col controls">
                                    <Field className="col-12"
                                        id="email"
                                        name="email"
                                        type="email"
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-3 col-form-label" htmlFor="password">Mot de passe</label>
                                <div className="col controls">
                                    <Field
                                        className="col-12"
                                        id="password"
                                        name="password"
                                        type="password"
                                    />
                                </div>
                            </div>

                            <button className="btn btn-primary" type="submit">Se connecter</button>
                            {props.alternativeLabel && <button className="btn btn-link" type="button" onClick={props.alternativeAction}>{props.alternativeLabel}</button>}

                        </Form>

                    </Formik>
                </div>
            </div>
            <div className="row">
                <button className="btn btn-link" type="button" onClick={props.resetAction}>{props.resetLabel}</button>
            </div>
        </div>
    );
}