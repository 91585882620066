import React from 'react';
import { PersotypeCard } from './PersotypeCard';

function Narrative(props) {
	
	const best = props.persotypes[props.result.best];
	const hasMajors = (props.result.majors.length > 0);
	const hasMinors = (props.result.minors.length > 0);
    return(
		<div className="result narrative">
			<div className="result preference mt-5">
				<p><span className="font-weight-bold">Persotype Principal</span></p>
				<p>Votre préférence est le type {best.name}</p>
				<PersotypeCard persotype={best} open={true} key={best.index} />
			</div>
			{hasMajors && 
				<Majors majors={props.result.majors} persotypes={props.persotypes}/>
			}
			{hasMinors && 
				<Minors minors={props.result.minors} persotypes={props.persotypes}/>
			}
		</div>
    );
}

function Majors(props){
	const plural = props.majors.length > 1;
	return (
		<div className="result majors mt-5">
				<p><span className="font-weight-bold">Autres Persotypes Majeurs</span></p>
				{plural ? 
					<p>Vous avez également obtenu une préférence élevée pour les persotypes suivants :</p> 
					: 
					<p>Vous avez également obtenu une préférence élevée pour le persotype {props.persotypes[props.majors[0]].name}.</p>
				}
				{props.majors.map((i)=>{
					return <PersotypeCard persotype={props.persotypes[i]} key={i} />;
				})}			
		</div>
	);
			
}

function Minors(props){
	const plural = props.minors.length > 1;
	return (
		<div className="result minors mt-5">
				<p><span className="font-weight-bold">Persotypes Mineurs</span></p>
				{plural ? 
					<p>Votre préférence pour les persotypes suivants est faible :</p> 
					: 
					<p>Vous avez une préférence faible pour le persotype {props.persotypes[props.minors[0]].name}.</p>
				}
				{props.minors.map((i)=>{
					return <PersotypeCard persotype={props.persotypes[i]} key={i} />;
				})}			
		</div>
	);
			
}

export default Narrative;