import { IndividualDownload } from "./IndividualDownload";
import presIcon from "./icons/presentation-learning-icon.svg";
import presNantes1 from "./files/persotypesNantes-1.pdf";
import presNantes2 from "./files/persotypesNantes-2.pdf";

export default function Downloads(props){
    return (
        <ul className="list-group">       
           <IndividualDownload title="Présentation des PersoTypes à Nantes le 19/09/2022 (première partie)" file={presNantes1} rotate={90} icon={presIcon} showPdf={true} suggestedDownloadName="PersoTypes_Nantes_1.pdf"/>
           <IndividualDownload title="Présentation des PersoTypes à Nantes le 19/09/2022 (deuxième partie)" file={presNantes2} rotate={90} icon={presIcon} showPdf={true} suggestedDownloadName="PersoTypes_Nantes_2.pdf"/>
        </ul>
    );
}