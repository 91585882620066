import React, { useState, useEffect } from 'react';
import VideoItem from './VideoItem';

export default function Videos(props) {

    const { visible } = props;
    const [playlistItems, setPlaylistItems] = useState([]);
    
    const [gapi, setGapi] = useState(null);
    useEffect(() => {
        if (visible) {
            const _gapi = window.gapi;
            setGapi(_gapi);
        }
    }, [visible]);


    useEffect(() => {
        if (gapi && playlistItems.length === 0) {
            function start() {
                // 2. Initialize the JavaScript client library.
                gapi.client.init({
                    'apiKey': 'AIzaSyBIOy57aUBFpPEC6cvJ_IIQETVGPvLa0ho'
                }).then(function () {
                    // 3. Initialize and make the API request.
                    return gapi.client.request({
                        'path': 'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PLDYrcviQGOnsTH-OkM0AEDh3v0MSugUgv&type=playlist&maxResults=50'
                    });
                }).then((response) => {
                    setPlaylistItems(response.result.items);
                }, function (reason) {
                    console.log(reason);
                });
            };

            // 1. Load the JavaScript client library.
            gapi.load('client', start);
        }
    }, [gapi, playlistItems]);

    return (
        <>
            {visible && playlistItems.map((item) => <VideoItem key={item.id} playListItem={item} />)}
        </>
    );
}

