import React, { useEffect, useState } from 'react';
import ResultHistogram from './ResultHistogram';
import Narrative from './Narrative/Narrative'
import BfbpRadar from './BfbpRadar';

const printedConsistency = (consistency, algoVersion) => {
    if (!algoVersion || algoVersion < 3) {
        return consistency;
    }
    return consistency.toFixed(0) + "%";
}

export default function FormDetails(props) {

    const { form, backToSearch, admin } = props;
    const [persotypes, setPersotypes] = useState(null);

    useEffect(() => {
        if (!Array.isArray(persotypes) || !persotypes.length) {
            fetch(process.env.REACT_APP_BACKEND_URL + "/api/persotypes")
                .then(response => response.json())
                .then(json => {
                    setPersotypes(json.items);
                });
        }
    }, [persotypes]);

    if (!Array.isArray(persotypes) || !persotypes.length) {
        // persotypes not available yet
        return <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>;
    } else {
        return <>
            <div><button type="submit" className="btn btn-primary" onClick={backToSearch}>Retourner à la liste</button></div>

            <h2>Résultat du test</h2>
            {form.result && <div className="result">
                <ResultHistogram scores={form.result.scores} persotypes={persotypes} />
                <Narrative result={form.result} persotypes={persotypes} />
                {admin &&
                    <section>
                        <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#scoreComponents" aria-expanded="false" aria-controls="scoreComponents">
                            Détails
                        </button>
                        <div className="collapse" id="scoreComponents">
                            <div className="card card-body">
                                Scores déduits de l'énnéagramme
                                <ResultHistogram scores={form.result.scoresFromEnneag} persotypes={persotypes} />
                            </div>
                            <div className="card card-body">
                                Scores déduits du BFBP
                                <ResultHistogram scores={form.result.scoresFromBfbp} persotypes={persotypes} />
                            </div>
                            <div className="col-sm-12 mt-5">
                                <p className="text-muted text-right mb-0">{printedConsistency(form.result.consistency, form.result.versions.algo)} {form.id}</p>
                                {form.result.bfbp && <p className="text-muted text-right mb-0">{`O${form.result.bfbp.OpennessBFI10} C${form.result.bfbp.ConscientiousnessBFI10} E${form.result.bfbp.ExtraversionBFI10} A${form.result.bfbp.AgreeablenessBFI10} N${form.result.bfbp.NeuroticismBFI10}`}</p>}
                                {form.result.bfbp && <p className="text-muted text-right mb-0">{`Ent${form.result.bfbp.Enthusiasm} Ass${form.result.bfbp.Assertiveness} Com${form.result.bfbp.Compassion} Pol${form.result.bfbp.Politeness} Ind${form.result.bfbp.Industriousness} Ord${form.result.bfbp.Orderliness} Vol${form.result.bfbp.Volatility} Wit${form.result.bfbp.Withdrawal} Exp${form.result.bfbp.Experience} Int${form.result.bfbp.Intellect}`}</p>}
                                {form.result.zScores && <p className="text-muted text-right mb-0">{`z: Ent${form.result.zScores.Enthusiasm.toFixed(2)} Ass${form.result.zScores.Assertiveness.toFixed(2)} Com${form.result.zScores.Compassion.toFixed(2)} Pol${form.result.zScores.Politeness.toFixed(2)} Ind${form.result.zScores.Industriousness.toFixed(2)} Ord${form.result.zScores.Orderliness.toFixed(2)} Vol${form.result.zScores.Volatility.toFixed(2)} Wit${form.result.zScores.Withdrawal.toFixed(2)} Exp${form.result.zScores.Experience.toFixed(2)} Int${form.result.zScores.Intellect.toFixed(2)}`}</p>}
                            </div>
                        </div>
                    </section>
                }
                {form.result.zScores && <section>
                    <h2>Base PersoTypes</h2>
                    En plus du profil PersoTypes, ce test mesure également un certain nombre de traits de notre personnalité :
                    <div className="card">
                        <div className="card-header" id="big5Header" style={{ backgroundColor: "grey" }}>
                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#big5Body" aria-expanded="true" aria-controls="big5Body">
                                <span className="font-weight-bold text-light text-decoration-none">Big Five</span>
                            </button>
                        </div>

                        <div id="big5Body" className="collapse" aria-labelledby="big5Header">
                            <div className="card-body">

                                <BfbpRadar zScores={form.result.zScores} />
                            </div>
                        </div>
                    </div>

                </section>}
            </div>
            }
            {!form.result && <div>Ce formulaire n'a pas de résultat enregistré en base.</div>}
            {admin && <>
                <h2>Retours de l'utilisateur</h2>
                {form.userEval && <table className="table">
                    <tbody>
                        <tr><th scope="row">Note</th><td>{form.userEval.rating}</td></tr>
                        <tr><th scope="row">Commentaire</th><td>{form.userEval.comment}</td></tr>
                    </tbody>
                </table>}
                {!form.userEval && <div>L'utilisateur n'a pas laissé d'évaluation.</div>}
            </>
            }
        </>;
    }
};