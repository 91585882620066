import { useEffect, useState } from "react";
import { CreateUser } from "./CreateUser";
import { UsersList } from "./UsersList";

export function Users(props) {

    const visible = props.visible;
    const [createdUser, setCreatedUser] = useState(null);

    useEffect(()=>{
        if (!visible){
            setCreatedUser(null);
        }
    }, [visible]);

    if (!visible) {
        return <></>;
    }
    return <div>
        <CreateUser user={props.user} createdUser={createdUser} callback={setCreatedUser}></CreateUser>
        <UsersList user={props.user} createdUser={createdUser}></UsersList>
    </div>;
}