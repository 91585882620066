import PersoTypeFiles from "../persotypes_files/PersoTypeFiles";
import Videos from "../videos/Videos";
import Downloads from "../downloads/Downloads";

import persotypeFilesIcon from './persotype_files_icon.svg';
import videosIcon from './videos_icon.svg';
import downloadsIcon from './downloads_icon.svg';
import homeIcon from './homeIcon.svg';
import testIcon from './test_icon.svg';
import usersIcon from './users_icon.svg';
import codesIcon from './codes_icon.svg';

import Home from "./Home";
import { useEffect, useState } from "react";
import Extract from "../extract/Extract";
import { Users } from "../users/Users";
import { Codes } from "../codes/Codes";

const TAB_HOME = "home";
const TAB_FILES = "files";
const TAB_VIDEOS = "vid";
const TAB_DOWNLOADS = "dl";
const TAB_EXTRACT = "ext";
const TAB_USERS = "users";
const TAB_CODES = "codes";


export default function MainTabs(props) {

    const { user, displayName } = props;

    const [selectedTab, setSelectedTab] = useState(TAB_HOME);
    const [admin, setAdmin] = useState(false);
    const [groups, setGroups] = useState([]);

    const activeTabClasses = "nav-link active justify-content-center align-items-center";
    const inactiveTabClasses = "nav-link justify-content-center align-items-center";
    const activePanelClasses = "tab-pane fade show active";
    const inactivePanelClasses = "tab-pane fade";

    useEffect(() => {
        if (user.uid) {
            console.log(`Testing if ${user.email} is admin...`);
            user.getIdTokenResult(true)
                .then((idTokenResult) => {
                    const isAdmin = idTokenResult.claims.admin === true;
                    console.log(`${user.email} is ${isAdmin ? "" : "not"} admin!`);
                    setAdmin(isAdmin);
                    if (idTokenResult.claims.groups) {
                        setGroups(idTokenResult.claims.groups);
                    } else {
                        setGroups([]);
                    };
                });
        } else {
            setAdmin(false);
            setGroups([]);
        }
    }, [user]);


    return (
        <>
            <ul className="nav nav-tabs my-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation" id="home-nav-item">
                    <button
                        className={selectedTab === TAB_HOME ? activeTabClasses : inactiveTabClasses}
                        onClick={() => setSelectedTab(TAB_HOME)}
                        type="button" role="tab"
                        aria-selected={selectedTab === TAB_HOME}
                        id="hom-tab">

                        <img src={homeIcon} alt="Icone Accueil" className="svg-icon mr-3" />Accueil
                    </button>
                </li>
                <li className="nav-item" role="presentation" id="files-nav-item">
                    <button
                        className={selectedTab === TAB_FILES ? activeTabClasses : inactiveTabClasses}
                        onClick={() => setSelectedTab(TAB_FILES)}
                        type="button" role="tab"
                        aria-selected={selectedTab === TAB_FILES}
                        id="files-tab">

                        <img src={persotypeFilesIcon} alt="Icone Fiches PersoTypes" className="svg-icon mr-3" />Fiches
                    </button>
                </li>
                <li className="nav-item" role="presentation" id="videos-nav-item">
                    <button
                        className={selectedTab === TAB_VIDEOS ? activeTabClasses : inactiveTabClasses}
                        onClick={() => setSelectedTab(TAB_VIDEOS)}
                        type="button" role="tab"
                        aria-selected={selectedTab === TAB_VIDEOS}
                        id="videos-tab">

                        <img src={videosIcon} alt="Icone Videos" className="svg-icon mr-3" />Vidéos
                    </button>
                </li>
                <li className="nav-item" role="presentation" id="downloads-nav-item">
                    <button
                        className={selectedTab === TAB_DOWNLOADS ? activeTabClasses : inactiveTabClasses}
                        onClick={() => setSelectedTab(TAB_DOWNLOADS)}
                        type="button" role="tab"
                        aria-selected={selectedTab === TAB_DOWNLOADS}
                        id="downloads-tab">

                        <img src={downloadsIcon} alt="Icone Téléchargements" className="svg-icon mr-3" />Téléchargements
                    </button>
                </li>
                {admin &&
                    <>
                        <li className="nav-item" role="presentation" id="extracts-nav-item">
                            <button
                                className={selectedTab === TAB_EXTRACT ? activeTabClasses : inactiveTabClasses}
                                onClick={() => setSelectedTab(TAB_EXTRACT)}
                                type="button" role="tab"
                                aria-selected={selectedTab === TAB_EXTRACT}
                                id="extract-tab">

                                <img src={testIcon} alt="Icone Téléchargements" className="svg-icon mr-3" />Résultats de tests
                            </button>
                        </li>
                        <li className="nav-item" role="presentation" id="users-nav-item">
                            <button
                                className={selectedTab === TAB_USERS ? activeTabClasses : inactiveTabClasses}
                                onClick={() => setSelectedTab(TAB_USERS)}
                                type="button" role="tab"
                                aria-selected={selectedTab === TAB_USERS}
                                id="users-tab">

                                <img src={usersIcon} alt="Icone Utilisateurs" className="svg-icon mr-3" />Utilisateurs
                            </button>
                        </li>
                    </>
                }
                {(admin || groups.length > 0) &&
                <>
                <li className="nav-item" role="presentation" id="extracts-nav-item">
                    <button
                        className={selectedTab === TAB_CODES ? activeTabClasses : inactiveTabClasses}
                        onClick={() => setSelectedTab(TAB_CODES)}
                        type="button" role="tab"
                        aria-selected={selectedTab === TAB_CODES}
                        id="extract-tab">

                        <img src={codesIcon} alt="Icone Codes" className="svg-icon mr-3" />Codes
                    </button>
                </li>
            </>
}
            </ul>
            <div className="tab-content" id="tabContent">
                <div className={selectedTab === TAB_HOME ? activePanelClasses : inactivePanelClasses} role="tabpanel" id="home" aria-labelledby="home-tab">
                    <Home user={user} displayName={displayName} />
                </div>
                <div className={selectedTab === TAB_FILES ? activePanelClasses : inactivePanelClasses} role="tabpanel" id="files" aria-labelledby="files-tab">
                    <PersoTypeFiles />
                </div>
                <div className={selectedTab === TAB_VIDEOS ? activePanelClasses : inactivePanelClasses} role="tabpanel" id="videos" aria-labelledby="videos-tab">
                    <Videos visible={selectedTab === TAB_VIDEOS} />
                </div>
                <div className={selectedTab === TAB_DOWNLOADS ? activePanelClasses : inactivePanelClasses} role="tabpanel" id="downloads" aria-labelledby="downloads-tab">
                    <Downloads />
                </div>
                {admin &&
                    <>
                        <div className={selectedTab === TAB_EXTRACT ? activePanelClasses : inactivePanelClasses} role="tabpanel" id="extract" aria-labelledby="extract-tab">
                            <Extract user={user} />
                        </div>
                        <div className={selectedTab === TAB_USERS ? activePanelClasses : inactivePanelClasses} role="tabpanel" id="users" aria-labelledby="users-tab">
                            <Users user={user} visible={selectedTab === TAB_USERS} />
                        </div>
                    </>
                }
                {(admin || groups.length > 0) &&
                    <>
                        <div className={selectedTab === TAB_CODES ? activePanelClasses : inactivePanelClasses} role="tabpanel" id="extract" aria-labelledby="extract-tab">
                            <Codes user={user} visible={selectedTab === TAB_CODES} admin={admin} />
                        </div>
                    </>
                }
            </div>
        </>
    )
}