import React from 'react';

export default function ResultTable(props) {

    const { forms, selectCallBack } = props;

    const toDateString = (secs) => {
        var t = new Date(Date.UTC(1970, 0, 1)); // Epoch
        t.setUTCSeconds(secs);
        return ("0" + t.getDate()).slice(-2) + "/" + ("0"+(t.getMonth()+1)).slice(-2) + "/" +t.getFullYear();
    }


    return <table className="table">
        <thead>
            <tr>
                <th scope="col">ID</th>
                <th scope="col">Date</th>
                <th scope="col">Code</th>
                <th scope="col">Genre</th>
                <th scope="col">Role</th>
                <th scope="col">Profession</th>
                <th scope="col">Note utilisateur</th>
                <th scope="col">Commentaire utilisateur</th>
            </tr>
        </thead>
        <tbody>
            {forms.map((form) => {
                return <tr key={form.id} style={{cursor: "pointer"}} onClick={()=>selectCallBack(form)}>
                    <td>{form.id}</td>
                    <td>{toDateString(form.created._seconds)}</td>
                    <td>{form.cat ? form.cat.code : ""}</td>
                    <td>{form.cat ? form.cat.gender : ""}</td>
                    <td>{form.cat ? form.cat.role : ""}</td>
                    <td>{form.cat ? form.cat.prof : ""}</td>
                    <td>{form.userEval ? form.userEval.rating : ""}</td>
                    <td>{form.userEval ? form.userEval.comment : ""}</td>
                </tr>;
            })}
        </tbody>
    </table>

}