export function CodeList(props) {

    const { user, codes, selectCallBack, loading, multipleFormsCallBack } = props;


    const selectCode = async (selectedCode) => {
        if (selectedCode === null) {
            selectCallBack(null);
        } else {
            // find the forms corresponding to the code
            user.getIdToken(true)
                .then((token) => {
                    const requestOptions = {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': ' application/json',
                            'Authorization': token
                        }
                    };
                    return fetch(process.env.REACT_APP_BACKEND_URL + '/api/codes/' + selectedCode.code + '/form', requestOptions);
                })
                .then((res) => res.json())
                .then((data) => {
                    if (!selectedCode.multi) {
                        selectCallBack(data.items[0]);
                    }else{
                        multipleFormsCallBack(selectedCode, data.items);
                    }
                });

        }
    };


    if (loading) {
        return <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
        </div>;
    }
    return (
        <div>
            <table className="table">
                <thead><tr>
                    <th scope="col">Code</th>
                    <th scope="col">Créé par</th>
                    <th scope="col">Etat</th>
                </tr></thead>
                <tbody>
                    {codes.map((code => {
                        return <tr key={code.code}>
                            <td>{code.code}</td>
                            <td>{code.created.user.name}</td>
                            <td>{code.status}
                                {code.status === "passé" && !code.multi &&
                                    <button className="btn btn-primary ml-3" onClick={() => selectCode(code)}>
                                        Voir le test
                                    </button>}
                                {code.status === "passé" && code.multi &&
                                    <button className="btn btn-primary ml-3" onClick={() => selectCode(code)}>
                                        Voir la liste de tests
                                    </button>}
                            </td>
                        </tr>
                    }))}
                </tbody>
            </table>
        </div>
    );
}