export default function VideoItem(props){

    const item = props.playListItem;

    return (
        <div className="container mb-5">
            <div className="row">
                <h3>{item.snippet.title}</h3>
            </div>
            <div className="row">
                <div className="col">
                <iframe id={item.id} width="560" height="315" src={`https://www.youtube.com/embed/${item.snippet.resourceId.videoId}?controls=0&amp;rel=0&amp;modestbranding=1&amp;list=${item.snippet.playlistId}`} title={item.snippet.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
		
                </div>
                <div className="col">
                    {item.snippet.description.split('#')[0]}
                </div>
            </div>
        </div>
    );
};
