import { useEffect, useState } from "react";
import FormDetails from "../extract/FormDetails";
import { CodeList } from "./CodeList";
import { CreateCode } from "./CreateCode";
import { FormList } from "./FormList";

const PAGE_SIZE = 10;

/**
 * Fetches the codes.
 * If startAfter is set, uses it in the request (pagination).
 * @param {number} navDirection 
 * @returns 
 */
const fetchCodes = (user, startAfter) => {
    return user.getIdToken(true)
        .then((token) => {
            const requestBody = {
                "limit": PAGE_SIZE
            }
            if (startAfter) {
                requestBody.startAfter = startAfter;
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': ' application/json',
                    'Authorization': token
                },
                body: JSON.stringify(requestBody)
            };
            return fetch(process.env.REACT_APP_BACKEND_URL + '/api/codes/list', requestOptions);
        })
        .then((res) => res.json())
        .then((data) => {
            return data.items;
        });
};

export function Codes(props) {

    const [createdCode, setCreatedCode] = useState(null);
    const [selectedForm, setSelectedForm] = useState(null);
    const [loading, setLoading] = useState(false);
    const [codes, setCodes] = useState([]);
    const [visitedPages, setVisitedPages] = useState([]);
    const [currentPageNumber, setCurrentPageNumber] = useState(0);

    const [multiCode, setMultiCode] = useState(null);
    const [formsToChooseFrom, setFormsToChooseFrom] = useState([]);

    const { visible, user, admin } = props;


    useEffect(() => {
        if (!visible) {
            setCreatedCode(null);
            setSelectedForm(null);
        }
    }, [visible]);

    useEffect(() => {
        if (!visible) {
            //do not load codes if the list will not be visible
            return;
        }
        setLoading(true);
        fetchCodes(user, null)
            .then((codes => {
                setCodes(codes);
                setVisitedPages([codes]);
                setCurrentPageNumber(0);
            }))
            .finally(setLoading(false));
    }, [user, createdCode, visible])


    const selectMultiCode = (code, forms) => {
        setMultiCode(code);
        setFormsToChooseFrom(forms);
    }

    /**
     * Navigates the paginated results in the given direction.
     * If the page was visisted before, shows the previous results, else fetches the next page.
     * @param {number} navDirection -1 for Previous, +1 for Next
     */
    const navigate = (navDirection) => {
        const nextPageNumber = currentPageNumber + navDirection;
        if (visitedPages[nextPageNumber]) {
            setCodes(visitedPages[nextPageNumber]);
        } else {
            setLoading(true);
            fetchCodes(user, codes[codes.length - 1].created.date)
                .then((codes) => {
                    setCodes(codes);
                    const nextVisitedPages = [...visitedPages];
                    nextVisitedPages[nextPageNumber] = codes;
                    setVisitedPages(nextVisitedPages);
                })
                .finally(setLoading(false));
        }
        setCurrentPageNumber(nextPageNumber);
    }




    if (!visible) {
        return <></>;
    }

    if (selectedForm) {
        return <FormDetails form={selectedForm} backToSearch={() => setSelectedForm(null)} admin={admin} />;
    }

    if (formsToChooseFrom.length > 0) {
        return <FormList code={multiCode} forms={formsToChooseFrom} backToSearch={() => setFormsToChooseFrom([])} selectCallBack={setSelectedForm} />;
    }

    return <div>
        <CreateCode user={user} callback={setCreatedCode} createdCode={createdCode} admin={admin} />

        <CodeList user={user} codes={codes} selectCallBack={setSelectedForm} loading={loading} multipleFormsCallBack={selectMultiCode} />
        <div className='row'>
            <div className='col-1 text-right'>
                {(currentPageNumber > 0) && <button className="btn btn-outline-primary btn-block" aria-label="Précédent" onClick={() => navigate(-1)}>
                    <span>&laquo;</span>
                    <span className="sr-only">Précédent</span>
                </button>
                }
            </div>
            <div className="col text-center">
                <span className="text-secondary">{(codes.length > 0) && <>Page {currentPageNumber + 1}</>}</span>
            </div>
            <div className='col-1 text-left'>
                {(codes.length === PAGE_SIZE) && <button className="btn btn-outline-primary btn-block" aria-label="Suivant" onClick={() => navigate(1)}>
                    <span>&raquo;</span>
                    <span className="sr-only">Suivant</span>
                </button>}
                {(codes.length < PAGE_SIZE) && <>&nbsp;</>}
            </div>
        </div>

    </div>;
};