import { sendPasswordResetEmail } from 'firebase/auth';
import { Formik, Field, Form } from 'formik';
import { auth } from "../../firebase-config";

export function ResetPassword(props) {


    return (
        <div>
            <legend>Réinitialisez votre mot de passe</legend>
            <div className="row justify-content-center">
                <div className="col-xs-12">
                    <Formik
                        initialValues={{
                            email: ''
                        }}
                        onSubmit={async (values) => {
                            try {
                                const userEmail = values.email;
                                const actionCodeSettings = {
                                    url: process.env.REACT_APP_HOSTING_URL
                                  };
                                await sendPasswordResetEmail(auth, userEmail, actionCodeSettings);
                            } catch (error) {
                                console.log(error.message);
                            }
                            props.callback();
                        }}
                    >
                        <Form className="border border-primary rounded p-3">

                            <div className="form-group row">
                                <label className="col-3 col-form-label" htmlFor="email">Email</label>
                                <div className="col controls">
                                    <Field className="col-12"
                                        id="email"
                                        name="email"
                                        type="email"
                                    />
                                </div>
                            </div>

                            <button className="btn btn-primary" type="submit">Réinitialiser le mot de passe</button>
                        </Form>

                    </Formik>
                </div>
            </div>
        </div>
    );
}