import React from 'react';
import { Formik, Form, Field } from 'formik';
import DatePicker from 'react-datepicker';
import NumericInput from 'react-numeric-input';
import 'react-datepicker/dist/react-datepicker.css';

const defaultFrom = new Date();
defaultFrom.setHours(0, 0, 0, 0);
const defaultTo = new Date();
defaultTo.setHours(23, 59, 59, 999);


const SelectionForm = (props) => (
    <div className="border border-primary rounded">
        <div className='m-2'>
            <Formik
                initialValues={{ startDate: defaultFrom, endDate: defaultTo, formID: '', code: '', gradeMin: null, gradeMax: null, hasComment: false, comment: '' }}
                validate={values => {
                    const errors = {};
                    if (values.startDate && values.endDate && values.startDate > values.endDate) {
                        errors.endDate = 'La date au-delà de laquelle les tests ne sont plus sélectionnés doit être ultérieure à la date à partir de laquel les tests sont sélectionnés';
                    }
                    if (values.gradeMin && values.gradeMax && values.gradeMin > values.gradeMax) {
                        errors.gradeMax = "La note maximale doit être supérieure ou égale à la note minimale";
                    }
                    return errors;
                }}
                onSubmit={values => {
                    props.onSubmit(values);
                }}
            >
                {({ errors, setFieldValue }) => (
                    <Form>
                        <div className='row'>
                            <div className='col-6'>
                                <div className='row'>
                                    <div className="form-group col-6">
                                        <label>ID du test :</label>
                                        <Field type="text" name="formID" className="form-control" />
                                    </div>
                                    <div className="form-group col-6">
                                        <label>Code :</label>
                                        <Field type="text" name="code" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='row'>
                                    <div className="form-group col-6">
                                        <label>Tests passés à partir du :</label>
                                        <Field name="startDate">
                                            {({ field }) => (
                                                <DatePicker
                                                    {...field}
                                                    selected={field.value}
                                                    onChange={date => setFieldValue('startDate', date)}
                                                    className="form-control"
                                                    dateFormat="dd/MM/yyyy"
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className="form-group col-6">
                                        <label>jusqu'au :</label>
                                        <Field name="endDate">
                                            {({ field }) => (
                                                <>
                                                    <DatePicker
                                                        {...field}
                                                        selected={field.value}
                                                        onChange={date => setFieldValue('endDate', date)}
                                                        className="form-control"
                                                        dateFormat="dd/MM/yyyy"
                                                    />
                                                    {errors.endDate && <div className="text-danger">{errors.endDate}</div>}
                                                </>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-6'>

                            </div>
                            <div className='col-6'>
                                <div className='row'>
                                    <div className="form-group col-6">
                                        <label>Note utilisateur minimale :</label>
                                        <Field name="gradeMin">
                                            {({ field }) => (
                                                <>
                                                    <NumericInput
                                                        {...field}
                                                        min={1} max={5} value={field.value}
                                                        className="form-control"
                                                        onChange={num => setFieldValue('gradeMin', num)} />
                                                </>
                                            )}
                                        </Field>
                                    </div>
                                    <div className="form-group col-6">
                                        <label>Note utilisateur maximale :</label>
                                        <Field name="gradeMax">
                                            {({ field }) => (
                                                <>
                                                    <NumericInput {...field}
                                                        min={1} max={5} value={field.value}
                                                        className="form-control"
                                                        onChange={num => setFieldValue('gradeMax', num)} />
                                                    {errors.gradeMax && <div className="text-danger">{errors.gradeMax}</div>}
                                                </>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary" disabled={props.loading === true}>
                            {props.loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}
                            Lancer la recherche
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    </div>
);

export default SelectionForm;