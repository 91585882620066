import React from 'react';
import { useState } from "react";
import FormDetails from './FormDetails';
import ResultTable from './ResultTable';
import SelectionForm from "./SelectionForm";

export default function Extract(props) {

    const { user } = props;
    const [forms, setForms] = useState([]);
    const [loading, setLoading] = useState(false);
    const [visitedPages, setVisitedPages] = useState([]);
    const [currentPageNumber, setCurrentPageNumber] = useState(0);
    const [selectedForm, setSelectedForm] = useState(null);
    const [selectionValues, setSelectionValues] = useState(null);
    const PAGE_SIZE = 15;

    const setValuesAndFetch = (values) => {
        fetchForms(values).then((forms => {
            setVisitedPages([forms]);
            setCurrentPageNumber(0);
        }));
        setSelectionValues(values);
    }

    /**
     * Navigates the paginated results in the given direction.
     * If the page was visisted before, shows the previous results, else fetches the next page.
     * @param {number} navDirection -1 for Previous, +1 for Next
     */
    const navigate = (navDirection) => {
        const nextPageNumber = currentPageNumber + navDirection;
        if (visitedPages[nextPageNumber]) {
            setForms(visitedPages[nextPageNumber]);
        } else {
            fetchForms(selectionValues, navDirection)
                .then((forms) => {
                    const nextVisitedPages = [...visitedPages];
                    nextVisitedPages[nextPageNumber] = forms;
                    setVisitedPages(nextVisitedPages);
                });
        }
        setCurrentPageNumber(nextPageNumber);
    }

    /**
     * Fetches the forms corresponding to the request values.
     * If navDirection is 1, fetches the next paginated results.
     * @param {*} values 
     * @param {number} navDirection 
     * @returns 
     */
    const fetchForms = (values, navDirection) => {
        setLoading(true);
        return user.getIdToken(true)
            .then((token) => {
                const requestBody = {
                    "limit": PAGE_SIZE,
                    "from": values.startDate,
                    "to": values.endDate,
                    "ID": values.formID,
                    "code": values.code,
                    "gradeMin": values.gradeMin,
                    "gradeMax": values.gradeMax
                };
                if (navDirection === 1) {
                    requestBody.startAfterDate = forms[forms.length - 1].created;
                }
                const requestOptions = {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': ' application/json',
                        'Authorization': token
                    }
                };
                return fetch(process.env.REACT_APP_BACKEND_URL + '/api/admin/answers/list', requestOptions)
            }).then((res) => {
                if (res.status === 200) {
                    return res.json();
                } else throw new Error("Server answered with status " + res.status);
            }).then((data) => {
                const items = data.items;
                setForms(items);
                return items;
            }).then((items) => {
                setLoading(false);
                return items;
            }).catch((error) => {
                console.log(error.toString());
                setLoading(false);
            });
    };

    return <>
        {!selectedForm && <>
            <SelectionForm loading={loading} onSubmit={setValuesAndFetch} />
            {(forms.length > 0) && <ResultTable loading={loading} forms={forms} selectCallBack={setSelectedForm} />}
            <div className='row'>
                <div className='col-1 text-right'>
                    {(currentPageNumber > 0) && <button className="btn btn-outline-primary btn-block" aria-label="Précédent" onClick={() => navigate(-1)}>
                        <span>&laquo;</span>
                        <span className="sr-only">Précédent</span>
                    </button>
                    }
                </div>
                <div className="col text-center">
                    <span className="text-secondary">{(forms.length > 0) && <>Page {currentPageNumber + 1}</>}</span>
                </div>
                <div className='col-1 text-left'>
                    {(forms.length === PAGE_SIZE) && <button className="btn btn-outline-primary btn-block" aria-label="Suivant" onClick={() => navigate(1)}>
                        <span>&raquo;</span>
                        <span className="sr-only">Suivant</span>
                    </button>}
                    {(forms.length < PAGE_SIZE) && <>&nbsp;</>}
                </div>
            </div>
            {(forms.length > 0) && <div className='text-right mt-2'>
                <button className="btn btn-primary">Exporter en .CSV</button>
            </div>}
        </>
        }
        {selectedForm && <FormDetails form={selectedForm} backToSearch={() => setSelectedForm(null)} admin={true}/>}
    </>
        ;
};