import ficheAssertif from './downloads/Fiche Assertif.pdf';
import ficheAgreable from './downloads/Fiche Agreable.pdf';
import ficheEnthousiaste from './downloads/Fiche Enthousiaste.pdf';
import ficheIntroverti from './downloads/Fiche Reflechi.pdf';
import ficheOrganise from './downloads/Fiche Organise.pdf';
import ficheLoyal from './downloads/Fiche Loyal.pdf';
import ficheConsciencieux from './downloads/Fiche Consciencieux.pdf';

import iconAssertif from './icons/Fiche_Assertif.svg';
import iconAgreable from './icons/Fiche_Agréable.svg';
import iconEnthousiaste from './icons/Fiche_Enthousiaste.svg';
import iconIntroverti from './icons/Fiche_Introverti.svg';
import iconOrganise from './icons/Fiche_Organisé.svg';
import iconLoyal from './icons/Fiche_Loyal.svg';
import iconConsciencieux from './icons/Fiche_Consciencieux.svg';

import { IndividualDownload } from '../downloads/IndividualDownload';

const persotypes = ["Assertif", "Agréable", "Enthousiaste", "Introverti", "Organisé", "Loyal", "Consciencieux"];
const files = [ficheAssertif, ficheAgreable, ficheEnthousiaste, ficheIntroverti, ficheOrganise, ficheLoyal, ficheConsciencieux];
const icons = [iconAssertif, iconAgreable, iconEnthousiaste, iconIntroverti, iconOrganise, iconLoyal, iconConsciencieux];

export default function PersoTypeFiles(props) {
    
    return (
        <div>
            Retrouvez ici la fiche récapitulative de chaque PersoType :
            <ul className="list-group">
            {persotypes.map(
                (persotype, index) =>
                    <IndividualDownload key={index} title={persotype} file={files[index]} icon={icons[index]} suggestedDownloadName={`fiche${persotype}.pdf`} showPdf={true}/>
            )}
            </ul>
        </div>
    );
}

