import { useState } from "react";
import {
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { auth } from "../firebase-config";

import './App.css';
import { Register } from "./auth/Register";
import { Login } from "./auth/Login";
import { ResetPassword } from "./auth/ResetPassword";
import MainTabs from "./contents/tabs/MainTabs";
import Navbar from "./Navbar";
import Footer from "./Footer";

function App() {

  const MODE_LOGIN = "login";
  const MODE_REGISTER = "reg";
  const MODE_RESET_PASSWORD = "reset";
  const MODE_AWAIT_PASSWORD_RESET = "await";

  const [user, setUser] = useState({});
  const [displayName, setDisplayName] = useState(null);
  const [mode, setMode] = useState(MODE_LOGIN);


  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
  });

  const logout = async () => {
    await signOut(auth);
  };

  const notAuthenticated = () => {
    return (
      <div>
        <br />

        {mode === MODE_LOGIN &&

          <div className="row justify-content-center">
            <Login resetLabel="Mot de passe oublié" resetAction={() => setMode(MODE_RESET_PASSWORD)} />
          </div>

        }
        {mode === MODE_REGISTER &&
          <div className="row justify-content-center">
            <Register
              alternativeLabel="Vous avez déjà un compte" alternativeAction={() => setMode(MODE_LOGIN)}
              displayNameAction={(displayName) => setDisplayName(displayName)}
              onUserCreated={() => setMode(MODE_LOGIN)} />
          </div>
        }
        {mode === MODE_RESET_PASSWORD &&
          <div className="row justify-content-center">
            <ResetPassword callback={() => setMode(MODE_AWAIT_PASSWORD_RESET)} />
          </div>
        }
        {mode === MODE_AWAIT_PASSWORD_RESET &&
          <div>
            <p>Vous avez demandé une réinitialisation de votre mot de passe.</p>
            <p>Veuillez vérifier vos mails, vous devriez avoir reçu un mail de notre part avec un lien vous permettant de créer un nouveau mot de passe.</p>
            <p>Si ce n'est pas le cas, veuillez attendre quelques minutes et n'oubliez pas de vérifier vos spams.</p>
          </div>
        }
      </div>
    );
  }

  const emailNeedsVerification = () => {
    return ( <></>
     /* <div>
        <p>Nous n'avons pas encore confirmé que vous êtes bien le propriétaire de l'adresse email renseignée.</p>
        <p>Veuillez vérifier vos mails, vous devriez avoir reçu un mail de notre part lorsque vous vous êtes enregistré.</p>
        <p>Si ce n'est pas le cas, veuillez attendre quelques minutes (cela peut prendre jusqu'à 10 minutes) et n'oubliez pas de vérifier vos spams.</p>
        <p>Lorsque vous recevrez cet email, cliquez sur le lien inclus et vous pourrez accéder aux fonctionnalités avancées de l'Espace Soignants.</p>

      </div> */
    );
  }

  const authenticated = () => {
    return (
      <div>
        {!user.emailVerified && emailNeedsVerification()}
        <MainTabs user={user} displayName={displayName} />
      </div>

    );
  }

  return (
    <>

      <Navbar user={user} logout={logout} />
      <main role="main" className="container Site-content">
        {process.env.REACT_APP_MODE === "STAGING" &&
          <p>STAGING MODE</p>
        }
        {!user && notAuthenticated()}
        {user && authenticated()}
      </main>
      <Footer />

    </>
  );
}

export default App;
