import { Formik, Field, Form } from 'formik';
import { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import * as Yup from 'yup';

const DisplayingErrorMessagesSchema = Yup.object().shape({
    title: Yup.string().required('Ce champ est obligatoire'),
    firstname: Yup.string().required('Ce champ est obligatoire'),
    lastname: Yup.string().required('Ce champ est obligatoire'),
    email: Yup.string().email('Email invalide').required('Ce champ est obligatoire'),
    password: Yup.string().min(6, 'Le mot de passe doit faire au moins 6 caractères').required('Ce champ est obligatoire'),
    groups: Yup.array().min(1, "Vous devez sélectionner au moins un groupe").required('Ce champ est obligatoire')
});


export function CreateUser(props) {

    const [groupOptions, setGroupOptions] = useState([]);
    const originalUser = props.user;
    const createdUser = props.createdUser;


    useEffect(() => {

        // initialize group options
        originalUser.getIdToken(true)
            .then((token) => {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': ' application/json',
                        'Authorization': token
                    }
                };
                return fetch(process.env.REACT_APP_BACKEND_URL + '/api/admin/groups/list', requestOptions);
            })
            .then((res) => res.json())
            .then((data) => {
                const groups = data.items;
                const options = [];
                groups.forEach((group) => {
                    options.push({
                        value: group.code,
                        label: group.label
                    });
                });
                setGroupOptions(options);
            });
    }, [originalUser]);


    return (
        <div>
            <div className="row justify-content-center">
                <div className="col-xs-12 col-sm-10 col-md-8">
                    <legend>Ajout d'un nouvel utilisateur</legend>
                    <Formik
                        initialValues={{
                            title: '',
                            firstname: '',
                            lastname: '',
                            email: '',
                            password: '',
                            groups: []
                        }}
                        validationSchema={DisplayingErrorMessagesSchema}
                        onSubmit={(values) => {
                            const userName = `${values.title} ${values.firstname} ${values.lastname}`;

                            originalUser.getIdToken(true)
                                .then((token) => {
                                    const requestBody = {
                                        email: values.email,
                                        groups: values.groups.map((option) => option.value),
                                        name: userName,
                                        pwd: values.password
                                    };
                                    const requestOptions = {
                                        method: 'POST',
                                        body: JSON.stringify(requestBody),
                                        headers: {
                                            'Accept': 'application/json',
                                            'Content-Type': ' application/json',
                                            'Authorization': token
                                        }
                                    };
                                    return fetch(process.env.REACT_APP_BACKEND_URL + '/api/admin/users', requestOptions)
                                        .then(() => props.callback(userName));
                                })

                        }
                        }
                    >
                        {({ errors, touched, setFieldValue }) => (
                            <Form className="border border-primary rounded p-3">

                                <div className="row form-group">
                                    <label htmlFor="titleControlGroup" className="col-3" id="title-radio-group">Titre</label>
                                    <div className="col controls">
                                        <div id="titleControlGroup" role="group" aria-labelledby="title-radio-group">

                                            <div className="form-check form-check-inline">
                                                <Field className="form-check-input" type="radio" name="title" value="M" />
                                                <label className="radio inline">
                                                    M
                                                </label>
                                            </div>

                                            <div className="form-check form-check-inline">
                                                <Field className="form-check-input" type="radio" name="title" value="Mme" />
                                                <label className="radio inline">
                                                    Mme
                                                </label>
                                            </div>

                                            <div className="form-check form-check-inline">
                                                <Field className="form-check-input" type="radio" name="title" value="Dr" />
                                                <label className="radio inline">
                                                    Dr
                                                </label>
                                            </div>

                                            <div className="form-check form-check-inline">
                                                <Field className="form-check-input" type="radio" name="title" value="Pr" />
                                                <label className="radio inline">
                                                    Pr
                                                </label>
                                            </div>
                                        </div>
                                        {touched.title && errors.title && <div className="text-danger">{errors.title}</div>}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-3 col-form-label" htmlFor="firstname">Prénom</label>
                                    <div className="col controls">
                                        <Field className="col-12" id="firstname" name="firstname" />
                                        {touched.firstname && errors.firstname && <div className="text-danger">{errors.firstname}</div>}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-3 col-form-label" htmlFor="lastname">Nom</label>
                                    <div className="col controls">
                                        <Field className="col-sm-12" id="lastname" name="lastname" />
                                        {touched.lastname && errors.lastname && <div className="text-danger">{errors.lastname}</div>}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-3 col-form-label" htmlFor="email">Email</label>
                                    <div className="col controls">
                                        <Field className="col-sm-12"
                                            id="email"
                                            name="email"
                                            type="email"
                                        />
                                        {touched.email && errors.email && <div className="text-danger">{errors.email}</div>}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-3 col-form-label" htmlFor="password">Mot de passe</label>
                                    <div className="col controls">
                                        <Field
                                            className="col-sm-12"
                                            id="password"
                                            name="password"
                                            type="password"
                                        />
                                        {touched.password && errors.password && <div className="text-danger">{errors.password}</div>}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-3 col-form-label" htmlFor="password">Groupe(s)</label>
                                    <div className="col controls">
                                        <Field name="groups">
                                            {({ field }) => (
                                                <>
                                                    <Select
                                                        name={field.name}
                                                        value={field.value}
                                                        onChange={(selectedOptions) => setFieldValue("groups", selectedOptions)}
                                                        placeholder="Groupe(s) auquel l'utilisateur appartient"
                                                        options={groupOptions}
                                                        isMulti={true}
                                                    />
                                                    {touched.groups && errors.groups && <div className="text-danger">{errors.groups}</div>}
                                                </>
                                            )}
                                        </Field>

                                    </div>
                                </div>
                                <button className="btn btn-primary" type="submit">Créer l'utilisateur</button>
                                {createdUser && <div className="alert alert-info mt-3" role="alert">
                                    L'utilisateur {createdUser} a bien été créé.
                                </div>}
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div >
    );
}