import { Formik, Field, Form } from 'formik';
import { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import * as Yup from 'yup';

const DisplayingErrorMessagesSchema = Yup.object().shape({
    group: Yup.object().nullable().required('Ce champ est obligatoire')
});



export function CreateCode(props) {

    const [groupOptions, setGroupOptions] = useState([]);
    const { user, createdCode, admin } = props;

    useEffect(() => {

        // initialize group options
        user.getIdToken()
            .then((token) => {

                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': ' application/json',
                        'Authorization': token
                    }
                };
                if (admin) {
                    // admins have access to all groups
                    return fetch(process.env.REACT_APP_BACKEND_URL + '/api/admin/groups/list', requestOptions);
                }
                // user is not admin, get only the groups to which it belongs
                return fetch(process.env.REACT_APP_BACKEND_URL + '/api/user/groups/list', requestOptions);
            })
            .then((res) => res.json())
            .then((data) => {
                const groups = data.items;
                const options = [];
                groups.forEach((group) => {
                    options.push({
                        value: group.code,
                        label: group.label
                    });
                });
                setGroupOptions(options);
            });
    }, [user, admin]);

    if (groupOptions.length === 0) {
        return <div>Loading...</div>
    }
    return <div>
        <div className="row justify-content-center">
            <div className="col-xs-12 col-sm-10 col-md-8">

                <Formik
                    initialValues={{
                        solo: true,
                        group: groupOptions.length === 1 ? groupOptions[0] : null
                    }}
                    validationSchema={DisplayingErrorMessagesSchema}
                    onSubmit={(values) => {
                        user.getIdToken()
                            .then((token) => {
                                const requestBody = {
                                    group: values.group.value,
                                    multi: !values.solo
                                };
                                const requestOptions = {
                                    method: 'POST',
                                    body: JSON.stringify(requestBody),
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': ' application/json',
                                        'Authorization': token
                                    }
                                };
                                return fetch(process.env.REACT_APP_BACKEND_URL + '/api/codes/create', requestOptions)
                                    .then((res) => res.json())
                                    .then((createdCode) => {
                                        props.callback(createdCode.code);
                                    });
                            });
                    }
                    }
                >
                    {({ values, errors, touched, setFieldValue }) => (
                        <Form className="border border-primary rounded p-3">
                            <legend>Créer un nouveau code pour faire passer un test PersoTypes</legend>

                            <div className="form-group row">
                                <label className="col-3 col-form-label" htmlFor="group">Groupe</label>
                                <div className="col controls">
                                    <Field name="group">
                                        {({ field }) => (
                                            <>
                                                <Select
                                                    name={field.name}
                                                    onChange={(selectedOption) => setFieldValue("group", selectedOption)}
                                                    placeholder="Groupe pour lequel le code est créé"
                                                    value={field.value}
                                                    options={groupOptions}
                                                    isMulti={false}
                                                    isDisabled={groupOptions.length === 1}
                                                />
                                                {touched.group && errors.group && <div className="text-danger">{errors.group}</div>}
                                            </>
                                        )}
                                    </Field>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <button className="btn btn-primary" type="submit">Créer le code</button>
                                </div>
                                <div className='col'>
                                    <label className="col-12 col-form-label" htmlFor="solo">
                                        <Field className="mr-2" name="solo" type="checkbox" />
                                        Code à usage unique
                                    </label>
                                </div>
                            </div>
                            {createdCode && <div className="alert alert-info mt-3" role="alert">
                                Le code {createdCode} a bien été créé.
                            </div>}
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    </div >
}