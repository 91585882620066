import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from "firebase/auth";
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { auth } from "../../firebase-config";

const authorizedEmailsRegexp = '(eldani.celine@gmail.com)|(simon.guimezanes@gmail.com)|(gaelle.(hignette|public|guimezanes)@gmail.com)|(.*@(chu-nantes.fr)|(chu-bordeaux.fr)|(luditeam.com))';

const DisplayingErrorMessagesSchema = Yup.object().shape({
    title: Yup.string().required('Ce champ est obligatoire'),
    firstname: Yup.string().required('Ce champ est obligatoire'),
    lastname: Yup.string().required('Ce champ est obligatoire'),
    email: Yup.string().email('Email invalide').required('Ce champ est obligatoire').matches(authorizedEmailsRegexp, "Cet email ne correspond pas à l'un de nos partenaires"),
    password: Yup.string().min(6, 'Le mot de passe doit faire au moins 6 caractères').required('Ce champ est obligatoire'),
    termsAndConditions: Yup.bool().oneOf([true], 'Vous devez accepter les conditions générales')
});

export function Register(props) {


    return (
        <div>
            <legend>Enregistrez-vous avec votre email professionnel pour avoir accès à l'Espace Soignants.</legend>
            <div className="row justify-content-center">
                <div className="col-xs-12 col-sm-10 col-md-8">
                    <Formik
                        initialValues={{
                            title: '',
                            firstname: '',
                            lastname: '',
                            email: '',
                            password: '',
                            termsAndConditions: false
                        }}
                        validationSchema={DisplayingErrorMessagesSchema}
                        onSubmit={async (values) => {
                            try {
                                await createUserWithEmailAndPassword(
                                    auth,
                                    values.email,
                                    values.password
                                );
                                props.onUserCreated();
                            } catch (error) {
                                console.log(`An error occured during registration : ${error.message}, ${JSON.stringify(error)}`);
                                return;
                            }
                            try {
                                const userName = `${values.title} ${values.firstname} ${values.lastname}`;
                                await updateProfile(auth.currentUser, { displayName: userName });
                                props.displayNameAction(userName);
                            } catch (error) {
                                console.log(`An error occured updating displayName : ${error.message}, ${JSON.stringify(error)}`);
                            }
                            try {
                                const currentUser = auth.currentUser;
                                const actionCodeSettings = {
                                    url: process.env.REACT_APP_HOSTING_URL
                                };
                                await sendEmailVerification(currentUser, actionCodeSettings);
                                console.log(`Email sent to ${currentUser.email}`);
                            } catch (error) {
                                console.log(`An error occured sending verification email : ${error.message}, ${JSON.stringify(error)}`);
                            }
                        }}
                    >
                        {({ errors, touched }) => (
                            <Form className="border border-primary rounded p-3">

                                <div className="row form-group">
                                    <label htmlFor="titleControlGroup" className="col-3" id="title-radio-group">Titre</label>
                                    <div className="col controls">
                                        <div id="titleControlGroup" role="group" aria-labelledby="title-radio-group">

                                            <div className="form-check form-check-inline">
                                                <Field className="form-check-input" type="radio" name="title" value="M" />
                                                <label className="radio inline">
                                                    M
                                                </label>
                                            </div>

                                            <div className="form-check form-check-inline">
                                                <Field className="form-check-input" type="radio" name="title" value="Mme" />
                                                <label className="radio inline">
                                                    Mme
                                                </label>
                                            </div>

                                            <div className="form-check form-check-inline">
                                                <Field className="form-check-input" type="radio" name="title" value="Dr" />
                                                <label className="radio inline">
                                                    Dr
                                                </label>
                                            </div>

                                            <div className="form-check form-check-inline">
                                                <Field className="form-check-input" type="radio" name="title" value="Pr" />
                                                <label className="radio inline">
                                                    Pr
                                                </label>
                                            </div>
                                        </div>
                                        {touched.title && errors.title && <div className="text-danger">{errors.title}</div>}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-3 col-form-label" htmlFor="firstname">Prénom</label>
                                    <div className="col controls">
                                        <Field className="col-12" id="firstname" name="firstname" />
                                        {touched.firstname && errors.firstname && <div className="text-danger">{errors.firstname}</div>}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-3 col-form-label" htmlFor="lastname">Nom</label>
                                    <div className="col controls">
                                        <Field className="col-sm-12" id="lastname" name="lastname" />
                                        {touched.lastname && errors.lastname && <div className="text-danger">{errors.lastname}</div>}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-3 col-form-label" htmlFor="email">Email</label>
                                    <div className="col controls">
                                        <Field className="col-sm-12"
                                            id="email"
                                            name="email"
                                            type="email"
                                        />
                                        {touched.email && errors.email && <div className="text-danger">{errors.email}</div>}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-3 col-form-label" htmlFor="password">Mot de passe</label>
                                    <div className="col controls">
                                        <Field
                                            className="col-sm-12"
                                            id="password"
                                            name="password"
                                            type="password"
                                        />
                                        {touched.password && errors.password && <div className="text-danger">{errors.password}</div>}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col">
                                        <Field type="checkbox" name="termsAndConditions" className="mr-2 mb-1" />
                                        <label htmlFor="termsAndConditions">J'accepte les <a href="https://persotypes.fr/mentions-legales.html" target="_blank">conditions générales</a></label>
                                        {touched.termsAndConditions && errors.termsAndConditions && <div className="text-danger">{errors.termsAndConditions}</div>}
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col">
                                        Votre email et vos information personnelles ne seront utilisés que pour vos interactions avec le site PersoTypes.fr et ne seront pas communiqués à des tiers.
                                    </div>
                                </div>

                                <button className="btn btn-primary" type="submit">S'enregistrer</button>
                                <button className="btn btn-link" type="button" onClick={props.alternativeAction}>{props.alternativeLabel}</button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}