export default function Footer(props) {
    return (
        <footer className="Site-footer">
            <div className="container">
                <span>
                    <div className="float-left text-secondary">2022 © PersoTypes.fr</div>
                    <div className="float-right"><a href="https://persotypes.fr/mentions-legales.html" className="text-secondary">Mentions légales</a></div>
                </span>
            </div>
        </footer>
    );
}