import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';

export default function PdfItem(props) {

    const { file, scale, visible, setVisible, rotate } = props;

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const options = {
        cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
        cMapPacked: true,
        standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`
    }

    const onDocumentLoadSuccess = (pdfDocument) => {
        setNumPages(pdfDocument.numPages);
        setPageNumber(1);
    }

    const onDocumentLoadError = (error) => {
        console.log(JSON.stringify(error));
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }


    return (
        <>{visible &&
            <div className="container my-5">
                <div className="row">
                    <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options={options} onLoadError={onDocumentLoadError}>
                        <Page pageNumber={pageNumber} scale={scale} rotate={rotate}/>
                    </Document>
                    <div className='float-right'>
                        <button type="button" className="close ml-n5" aria-label="Close" onClick={() => setVisible(false)}>
                            <span aria-hidden="true"><h2>&times;</h2></span>
                        </button>

                    </div>
                </div>
                <div className="row">
                    
                        Page {pageNumber || (numPages ? 1 : '--')} sur {numPages || '--'}
                    
                        <button
                            type="button"
                            className="btn btn-secondary ml-5"
                            disabled={pageNumber <= 1}
                            onClick={previousPage}
                        >
                            &larr;
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary ml-1"
                            disabled={pageNumber >= numPages}
                            onClick={nextPage}
                        >
                            &rarr;
                        </button>
                    
                </div>
            </div>}
        </>
    );
}

