export default function Navbar(props) {
    const { user, logout } = props;
    return (
        <nav className="navbar navbar-custom navbar-expand-lg navbar-dark static-top">
            <div className="container">
                <a className="navbar-brand" href="https://persotypes.fr">
                    <img src="https://persotypes.fr/img/Logo_PersoType.png" alt="PersoTypes Logo" height="60" />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="nav navbar-nav mr-auto">
                        <li className="nav-item">
                            <a className="nav-link" href="https://form.persotypes.fr">Passer le test</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://persotypes.fr/les-persotypes.html">Les PersoTypes</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://persotypes.fr/faq.html">FAQ</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://persotypes.fr/contact.html">Contact</a>
                        </li>
                        <li className="nav-item active">
                            <a className="nav-link" href="#0">Espace Soignants</a>
                        </li>
                    </ul>
                    {user &&
                        <ul className="nav navbar-nav ml-auto">
                            <li className="nav-item btn-nav navbar-right">
                                <button className="btn navbar-btn btn-link text-white-50" onClick={logout}>Se déconnecter</button>
                            </li>
                        </ul>
                    }
                </div>
            </div>
        </nav>
    );
}